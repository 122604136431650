.notificator {
   position: fixed;
   top: 35px;
   right: 30px;
   z-index: 100;
   margin-left: 30px;
}

.notificator__body {
   position: relative;
   padding: 20px 20px 0 0;
}

.notificator__btn {
   position: absolute;
   top: 0;
   right: 0;
   width: 56px;
   height: 56px;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 50%;
   background-color: rgba(64, 183, 217, 1);
   cursor: pointer;
}

.notificator__btn svg {
   width: 32px;
   height: 32px;
   transition: all 0.1s ease;
}

@keyframes shake-animation {
   0% {
     transform: rotate(0deg);
   }
   25% {
     transform: rotate(20deg);
   }
   50% {
     transform: rotate(-40deg);
   }
   75% {
     transform: rotate(0deg);
   }
   100% {
     transform: rotate(-20deg);
   }
}

.notificator__btn:hover svg {
   animation-name: shake-animation;
   animation-duration: 0.25s;
   animation-iteration-count: 1;
   animation-timing-function: linear;
}

.notificator__notification-item {
   max-width: 535px;
   padding: 24px;
   display: flex;
   flex-direction: column;
   gap: 16px;
   border-radius: 6px;
   background-color: rgba(222, 248, 255, 1);
}

.notification-item__text {
   font-weight: 500;
   font-size: 16px;
   line-height: 22.4px;
   color: rgba(37, 37, 37, 1);
}

.notification-item__btn {
   align-self: flex-start;
   display: flex;
   align-items: center;
   gap: 9px;
   font-size: 16px;
   line-height: 19.36px;
   color: rgba(64, 183, 217, 1);
   cursor: pointer;
   transition: all 0.3s ease;
}

.notification-item__btn svg {
   transition: all 0.3s ease;
}

.notification-item__btn:hover svg {
   transform: translateX(2px);
}