.manager-permission-tooltip {
   position: relative;
   margin-left: -16px;
}

.manager-permission-tooltip__btn {
   cursor: pointer;
}

.manager-permission-tooltip:hover .manager-permission-tooltip__tooltip,
.manager-permission-tooltip_active .manager-permission-tooltip__tooltip {
   cursor: pointer;
   visibility: visible;
   opacity: 1;
}

.manager-permission-tooltip__tooltip {
   position: absolute;
   z-index: 10;
   top: calc(100% + 10px);
   left: -5px;
   box-shadow: 0 0 26px 0 rgba(73, 154, 228, 0.16);
   visibility: hidden;
   opacity: 0;
   transition: all 0.3 ease;
   pointer-events: none;
}

.manager-permission-tooltip__tooltip-body {
   position: relative;
   width: 270px;
   padding: 20px;
   border-radius: 6px;
   background-color: white;
   font-weight: 500;
   font-size: 16px;
   line-height: 16.94px;
}

.manager-permission-tooltip__tooltip-body::before {
   content: "";
   border-left: 8px solid transparent;
   border-right: 8px solid transparent;
   border-bottom: 12px solid white;
   position: absolute;
   top: -10px;
   left: 5px;
}