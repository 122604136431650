.auth {
   box-sizing: border-box;
   margin: 0;
   padding: 0 15px;
   width: 100%;
   height: 100vh;
   background-color: white;
   font-family: 'Roboto';
   display: flex;
   justify-content: center;
   align-items: center;
}

.login-card {
   flex: 1 1 auto;
   background-color: #ffffff;
   border-radius: 20px;
   max-width: 670px;
}

.login-card.manager-login {
   max-width: 600px;
}

@media screen and (min-width: 575.99px) {
   .login-card {
      position: relative;
      box-shadow: 0 6px 60px 0 rgba(211, 223, 228, 0.5);
      border: 1px solid #f0f0f0;
   }

   .login-card-wrapper {
      padding: 60px;
   }

   .login-card-header-title {
      font-size: 20px;
      line-height: 24px;
   }
}

.login-card-header-wrapper {
   display: flex;
   flex-direction: column;
   gap: 20px;
}

@media screen and (max-width: 575.99px) {
   .login-card-header-wrapper {
      gap: 16px;
   }
}

.login-card-header {
   position: relative;
}

.login-card-header__return-btn {
   position: absolute;
   top: 50%;
   left: 0;
   transform: translateY(-50%);
   width: 32px;
   height: 32px;
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;
}

.login-card-header__return-btn img {
   margin: 0;
}

@media screen and (max-width: 575.99px) {
   .login-card-header__return-btn {
      flex: 0 0 24px;
      width: 24px;
      height: 24px;
   }

   .login-card-header__return-btn img {
      max-width: 8px;
   }
}

.login-card-header-title {
   font-weight: 600;
   font-size: 28px;
   line-height: 32px;
   text-align: center;
}

.login-card-header__description {
   font-size: 16px;
   line-height: 20px;
   text-align: center;
}

@media screen and (max-width: 575.99px) {
   .login-card-header-title {
      font-size: 20px;
      line-height: 24px;
   }
   
   .login-card-header__description {
      font-size: 14px;
      line-height: 18px;
   }
}

.login-card-body-wrapper {
   padding: 20px 0 0 0;
}

.login-card-body-wrapper.manager-login {
   padding-top: 32px;
   display: flex;
   flex-direction: column;
   gap: 12px;
}

@media screen and (max-width: 575.99px) {
   .login-card-body-wrapper.manager-login {
      padding-top: 20px;
   }
   
   .login-card-header__description {
      font-size: 14px;
      line-height: 18px;
   }
}

.login-card-body-text {
   font-size: 16px;
   line-height: 20px;
   color: #212529;
   text-align: center;
}

.login-card-body-text a {
   color: #00ABEE;
}

.login-card-button-wrapper {
   padding-top: 32px;
   text-align: center;
   font-size: 1rem;
   font-weight: 400;
   line-height: 1.5;
   color: #212529;
   display: flex;
   justify-content: space-between;
   gap: 10px;
   width: 100%;
}

.login-card-button-wrapper__oauth-link {
   flex: 0 1 265px;
   display: flex;
}

.login-card-button-wrapper__oauth-link .login-card-button-wrapper__btn {
   flex: 1 1 auto;
}

.login-card-button-wrapper__btn {
   flex: 0 1 265px;
   border-radius: 12px;
}

.login-card-forgot-password {
   margin-top: 12px;
   display: flex;
   justify-content: center;
   align-items: center;
}

.login-card-forgot-password__btn {
   font-weight: 400;
   font-size: 14px;
   line-height: 16px;
   color: #00ACEF;
   cursor: pointer;
}

.login-card-forgot-password__btn:hover {
   color: rgb(50, 164, 196);
}

.login-card-manager-button {
   color: #fff;
   background: #00acef;
   padding: 6px;
   cursor: pointer;
   display: inline;
   font-weight: 400;
   text-align: center;
   white-space: nowrap;
   vertical-align: middle;
   user-select: none;
   border: 1px solid transparent;
   font-size: 1rem;
   line-height: 1.5;
   border-radius: 0.25rem;
   width: 100%;
   margin-left: 24px;
}

.login-card-manager-button:hover {
   background-color: #0062cc;
   border-color: #005cbf;
}

.login-card-button {
   color: #fff;
   background: #979797;
   border-color: #979797;
   padding: 6px 0;
   cursor: pointer;
   display: inline;
   font-weight: 400;
   text-align: center;
   white-space: nowrap;
   vertical-align: middle;
   user-select: none;
   border: 1px solid transparent;
   font-size: 16px;
   line-height: 1.5;
   border-radius: 0.25rem;
   width: 100%;
   margin-right: 24px;
}

.login-card-button:hover {
   background: #817e7e;
   border-color: #817e7e;
}

.login-card-credentials-field {
   width: 100%;
   height: 42px;
   padding: 12px 16px;
   border: 1px solid #E4E4E4;
   border-radius: 12px;
   box-sizing: border-box;
   outline: none;
   font-size: 14px;
   line-height: 18px;
   color: #8C9497;
}

.login-card-credentials-field.password {
   padding-right: 44px;
}

.manager-login-card-reject {
   color: #fff;
   background: #979797;
   border-color: #979797;
   padding: 6px 0;
   cursor: pointer;
   display: inline;
   font-weight: 400;
   text-align: center;
   white-space: nowrap;
   vertical-align: middle;
   user-select: none;
   border: 1px solid transparent;
   font-size: 16px;
   line-height: 1.5;
   border-radius: 0.25rem;
   padding: 8px 14px;
}

.manager-login-card-reject:hover {
   background: #817e7e;
   border-color: #817e7e;
}

.manager-login-card-signin {
   color: #fff;
   background: #00acef;
   padding: 6px;
   cursor: pointer;
   display: inline;
   font-weight: 400;
   text-align: center;
   white-space: nowrap;
   vertical-align: middle;
   user-select: none;
   border: 1px solid transparent;
   font-size: 1rem;
   line-height: 1.5;
   border-radius: 0.25rem;
   padding: 8px 14px;
}

.manager-login-card-signin:hover {
   background-color: #0062cc;
   border-color: #005cbf;
}

::-webkit-scrollbar {
   display: none;
}

@media (max-width: 575.99px) {
   .login-card-header-text {
      font-size: 20px;
      line-height: 24px;
   }

   .login-card-body-text {
      font-size: 14px;
      line-height: 18px;
   }

   .login-card-button-wrapper {
      flex-direction: column;
   }

   .login-card-button-wrapper__oauth-link {
      flex: none;
   }
   
   .login-card-button-wrapper__btn {
      flex: none;
   }
}

@media (max-width: 900px) {
   .login-card {
      width: 95%;
   }

   .login-card-manager-button {
      margin-left: 0;
      margin-top: 15px;
   }

   .manager-login-card-signin {
      margin-top: 15px;
   }
}

.amo-login-error-wrap {
   max-width: 405px;
   margin: auto;
}

.amo-login-error-head {
   font-weight: 700;
   font-size: 22px;
   line-height: 28px;
   text-align: center;
   margin-bottom: 12px;
}

.amo-login-error-message {
   font-weight: 400;
   font-size: 14px;
   line-height: 16px;
   text-align: center;
}

.amo-login-error-link {
   color: #00ACEF;
   text-decoration: underline;
}

.login-form__status {
   color: rgba(217, 64, 64, 1);
   text-align: center;
}

.login-form__status:not(:last-child) {
   margin-bottom: 10px;
}

.login-form-control {
   position: relative;
   display: flex;
   flex-direction: column;
   gap: 4px;
}

.login-form-control label {
   font-size: 12px;
   line-height: 16px;
   color: #252525;
}

.manager-login__show-btn {
   position: absolute;
   bottom: 12px;
   right: 12px;
   width: 20px;
   opacity: 0.5;
   cursor: pointer;
}

.manager-login__show-btn_active {
   opacity: 1;
}

.manager-login__submit-btn {
   flex: 1 1 auto;
   border-radius: 12px;
}

.manager-login-notification {
   position: fixed;
   top: 46px;
   right: 46px;
}

.manager-login-notification__body {
   position: relative;
   padding: 20px 20px 0 0;
}

.manager-login-notification__icon {
   position: absolute;
   top: 0;
   right: 0;
   width: 56px;
   height: 56px;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 50%;
   background-color: rgba(64, 183, 217, 1);
}

.manager-login-notification__text {
   padding: 24px 40px;
   border-radius: 6px;
   background-color: rgba(222, 248, 255, 1);
   box-shadow: 0 6px 20px 0 rgba(213, 230, 235, 0.4);
   font-weight: 500;
   font-size: 16px;
   line-height: 22.4px;
}