.add-contact {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: rgba(0, 0, 0, 0.2);
   z-index: 999;
}

.add-contact-enter {
   opacity: 0;
}

.add-contact-enter-active {
   opacity: 1;
   transition: opacity 500ms;
}

.add-contact.exit {
   opacity: 1;
}

.add-contact-exit-active {
   opacity: 0;
   transition: opacity 500ms;
}

.add-contact-wrapper {
   max-width: 500px;
   position: fixed;
   background: #fff;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   padding: 50px;
   border-radius: 6px;
   box-shadow: 0px 6px 50px rgba(180, 185, 187, 0.6);
}

.add-contact-header {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-content: center;
   margin-bottom: 28px;
}

.add-contact-header-text {
   text-align: center;
   font-weight: 600;
   font-size: 20px;
   line-height: 24px;
}

.add-contact-form__status {
   margin-bottom: 20px;
   color: #d94040;
   text-align: center;
}

.add-contact-form-dropdown .dropdown-wrapper {
   min-width: 337px;
}

.add-contact-form-control {
   margin-bottom: 16px;
   width: 100%;
}

.add-contact-form-control-block {
   margin-bottom: 22px;
}

.add-contact-form-control-block:last-child {
   margin-bottom: 0;
}

.add-contact-textarea {
   min-width: 337px;
   width: 100%;
   resize: none;
   height: 125px;
   display: inline-block;
   outline: none;
   border: 1px solid #e4e4e4;
   border-radius: 4px;
   padding: 14px 20px;
   font-size: 14px;
   line-height: 21px;
}

.add-contact-textarea:focus {
   min-width: 337px;
   width: 100%;
   resize: none;
   height: 125px;
   display: inline-block;
   outline: none;
   border: 1px solid #40b7d9;
   border-radius: 4px;
   padding: 14px 20px;
   font-size: 14px;
   line-height: 21px;
}

.add-contact-textarea-red {
   width: 100%;
   resize: none;
   height: 125px;
   display: inline-block;
   outline: none;
   border: 1px solid #d94040;
   border-radius: 4px;
   padding: 14px 20px;
   font-size: 14px;
   line-height: 21px;
   color: #d94040;
}

.add-contact-textarea-red::placeholder {
   font-weight: 300;
   font-size: 14px;
   color: #d94040;
   opacity: 0.3;
}

.add-contact-form-control-label,
.add-contact-form-control-label-red {
   display: flex;
   justify-content: space-between;
   align-items: center;
   gap: 5px;
   font-style: normal;
   font-weight: 400;
   font-size: 12px;
   line-height: 15px;
   padding-bottom: 8px;
}

.add-contact-form-control-label-red {
   color: #d94040;
}

.add-contact-form-validation-message {
   margin-bottom: 10px;
   padding-bottom: 0;
}

.add-contact-form-control-input {
   min-width: 337px;
   width: 100%;
   height: 44px;
   border: 1px solid #e4e4e4;
   border-radius: 6px;
   outline: none;
   text-indent: 20px;
}

.input-eye {
   fill: #e4e4e4;
   position: relative;
   bottom: -3px;
   left: -36px;
}

.input-eye-active {
   fill: black;
   position: relative;
   bottom: -3px;
   left: -36px;
}

.input-eye-red {
   fill: #d94040;
   position: relative;
   bottom: -3px;
   left: -36px;
}

.input-eye {
   fill: #e4e4e4;
   position: relative;
   bottom: -3px;
   left: -36px;
}

.input-eye-active {
   fill: black;
   position: relative;
   bottom: -3px;
   left: -36px;
}

.input-eye-red {
   fill: #d94040;
   position: relative;
   bottom: -3px;
   left: -36px;
}

.add-contact-form-control-input:focus {
   min-width: 337px;
   width: 100%;
   height: 44px;
   border: 1px solid #40b7d9;
   border-radius: 6px;
   outline: none;
   text-indent: 20px;
}

.add-contact-form-control-input-red {
   min-width: 337px;
   width: 100%;
   height: 44px;
   border: 1px solid #d94040;
   color: #d94040;
   border-radius: 6px;
   outline: none;
   text-indent: 20px;
}

.add-contact-form-control-warning {
   display: flex;
   flex-direction: row;
   gap: 8px;
}

.warning-icon {
   width: 14px;
   height: 14px;
   fill: #d94040;
}

.add-contact-form-control-input::placeholder {
   font-weight: 300;
   font-size: 14px;
   line-height: 64px;
   color: #979797;
}

.add-contact-form-control-input-red::placeholder {
   font-weight: 300;
   font-size: 16px;
   line-height: 64px;
   color: #d94040;
   opacity: 0.3;
}

.add-contact-form-control-input-message {
   padding: 8px;
   text-indent: 8px;
}

.add-contact-form-submit {
   width: 100%;
   padding-top: 18px;
   display: flex;
   flex-direction: column;
}

.add-contact-form-submit-button {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   padding: 18px 51px;
   border-radius: 6px;
   background-color: #00acef;
   color: #fff;
   border: none;
   cursor: pointer;
   width: 100%;
   font-style: normal;
   font-weight: 500;
   font-size: 16px;
   line-height: 19px;
}

.add-contact-form-submit-button:hover {
   background-color: #4fc6e8;
}

.add-contact-form-submit-button:active {
   background-color: #39b0d2;
}

.add-contact-form-submit-button:disabled {
   background-color: #b8c5c9;
}

.add-contact-color-control {
   position: relative;
   margin-top: 22px;
   margin-bottom: 12px;
}

.add-contact-form-color-block {
   margin-bottom: 23px;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
}

.add-contact-form-color-label {
   font-style: normal;
   font-weight: 400;
   font-size: 12px;
   line-height: 15px;
   padding-bottom: 16px;
   display: block;
}

.add-contact-form-color-item {
   border-radius: 50%;
   width: 38px;
   height: 38px;
   border: none;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
}

.add-contact-form-color-item-active {
   width: 38px;
   height: 38px;
   border: none;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
}

.add-contact-form-another-color-item {
   border-radius: 50%;
   width: 30px;
   height: 30px;
   border: none;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
}

.add-contact-form-another-color-item-active {
   /*border-radius: 50%;*/
   width: 30px;
   height: 30px;
   border: none;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
}

.others {
   background: linear-gradient(
         0deg,
         rgba(37, 37, 37, 0.14),
         rgba(37, 37, 37, 0.14)
      ),
      linear-gradient(
         142.13deg,
         #ec4646 17.45%,
         #eca046 24.71%,
         #ece546 33.18%,
         #99ec46 41.64%,
         #46ecd8 48.69%,
         #469cec 57.86%,
         #464dec 65.62%,
         #cb46ec 72.32%,
         #ec46bd 78.31%,
         #ef3434 85.16%
      );
}

.add-contact-form-another-color {
   position: absolute;
   top: -90px;
   right: -274px;
   background-color: #ffffff;
   padding: 20px;
   box-shadow: 0 6px 26px rgba(73, 154, 228, 0.16);
   border-radius: 6px;
   display: flex;
   flex-direction: column;
   align-items: center;
}

.add-contact-form-another-color-block {
   display: flex;
   justify-content: center;
   flex-direction: row;
   flex-wrap: wrap;
   max-width: 214px;
   gap: 16px;
   margin-bottom: 32px;
}

.add-contact-form-another-color-submit-button {
   font-style: normal;
   font-weight: 600;
   font-size: 14px;
   line-height: 17px;
   color: #40b7d9;
   background-color: #ffffff;
   border: none;
   cursor: pointer;
}

.add-contact-close-button {
   background-color: transparent;
   border-radius: 32px;
   color: white;
   border: none;
   cursor: pointer;
}

@media (max-width: 900px) {
   .add-contact-wrapper {
      width: 85% !important;
      padding: 30px 18px;
   }

   .add-contact-header-text {
      text-align: center;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
   }

   .add-contact-form-control-input {
      width: 100%;
   }

   .add-contact-form-control-input:focus {
      width: 100%;
   }

   .add-contact-form-control-input-red {
      width: 100%;
      height: 44px;
      border: 1px solid #d94040;
      border-radius: 6px;
      outline: none;
      text-indent: 15px;
   }

   .warning-icon {
      width: 12px;
      height: 12px;
      fill: #d94040;
   }

   .add-contact-form-color-item {
      border-radius: 50%;
      width: 34px;
      height: 34px;
      border: none;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
   }

   .add-contact-form-another-color {
      position: absolute;
      top: 82px;
      right: -2px;
      background-color: #ffffff;
      /*padding: 20px;*/
      box-shadow: 0 6px 26px rgba(73, 154, 228, 0.16);
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      align-items: center;
   }
}
