.drop-file-input-item {
   padding: 4px 8px 8px;
   border-radius: 8px;
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 1px;
   background-color: rgba(239, 250, 255, 1);
}

.drop-file-input-item__delete-btn {
   flex: 0 0 16px;
   width: 16px;
   height: 16px;
   align-self: flex-end;
   cursor: pointer;
}

.drop-file-input-item__delete-btn img {
   margin: 0;
}

.drop-file-input-item__name {
   align-self: stretch;
   font-weight: 400;
   font-size: 12px;
   line-height: 16.8px;
   overflow: hidden;
   text-overflow: ellipsis;
   text-align: center;
   white-space: nowrap;
}

.drop-file-input-item__size {
   font-weight: 400;
   font-size: 10px;
   line-height: 14px;
   color: rgba(140, 148, 151, 1);
}