hr {
   opacity: 0.5;
}

.manager-mainbar-wrapper {
   padding: 30px;
   overflow: auto;
   width: 100%;
   box-shadow: 0px 6px 30px rgba(213, 230, 235, 0.4);
   border-radius: 6px;
}

.manager-mainbar-user {
   padding: 30px 30px 0 30px;
   display: flex;
   align-items: center;
   margin-bottom: 30px;
}

.manager-mainbar-user-photo {
   width: 64px;
   height: 64px;
   border-radius: 50%;
   margin: 0;
}

.manager-edit-photo-button {
   position: relative;
   width: 21px;
   height: 21px;
   top: 22px;
   right: 22px;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 50%;
   border: none;
   background-color: #00acef;
   cursor: pointer;
}

.manager-mainbar-user__info {
   display: flex;
   flex-direction: column;
   gap: 2px;
}

.manager-mainbar-user_name {
   font-style: normal;
   font-weight: 600;
   font-size: 22px;
   line-height: 136%;
}

.manager-mainbar-user__status {
   align-self: flex-start;
   padding: 4px 8px;
   border-radius: 24px;
   background-color: #FFCCCC;
   font-size: 14px;
   line-height: 16.94px;
   color: #ED5656;
   text-align: center;
}

.manager-mainbar-user__status_active {
   background-color: #C8FFCA;
   color: #43DB4A;
}

.mainbar-add-manager-title {
   font-weight: 800;
   font-size: 18px;
   line-height: 25px;
   text-align: left;
   color: #000000;
   margin-bottom: 36px;
}

.manager-mainbar-page-block {
   padding: 0 30px 0 30px;
   display: flex;
   flex-direction: row;
   gap: 100px;
}

.manager-mainbar-page-button {
   border: none;
   background-color: white;
   font-style: normal;
   font-weight: 500;
   font-size: 14px;
   line-height: 17px;
   padding-bottom: 17px;
   cursor: pointer;
}

.manager-mainbar-page-button-active {
   border: none;
   background-color: white;
   font-style: normal;
   font-weight: 500;
   font-size: 14px;
   line-height: 17px;
   padding-bottom: 17px;
   border-bottom: 1px solid #00acef;
   cursor: pointer;
}

.manager-mainbar-info-wrapper {
   padding: 40px;
}

.mainbar-add-manager-control-form {
   width: 100%;
   margin-bottom: 22px;
   display: flex;
   flex-direction: column;
   gap: 8px;
}

.mainbar-add-manager-control-form:last-child {
   margin-bottom: 40px;
}

.mainbar-add-manager-control-form label {
   padding-right: 36px;
   display: inline-block;
   font-style: normal;
   font-weight: 400;
   font-size: 12px;
   line-height: 15px;
}

.mainbar-add-manager-control-form input {
   padding-left: 20px;
   background: #ffffff;
   border: 1px solid #e4e4e4;
   box-sizing: border-box;
   border-radius: 6px;
   width: 360px;
   height: 38px;
   font-weight: 400;
   font-size: 14px;
   line-height: 17px;
   display: inline;
}

.mainbar-add-manager-control-form-container {
   display: flex;
   align-items: center;
   gap: 10px;
}

.mainbar-add-manager-control-form-container .mainbar-add-manager-control-form {
   width: auto;
}

.mainbar-manager-button-block {
   display: flex;
   flex-direction: column;
   gap: 15px;
   align-items: flex-start;
}

.mainbar-add-manager-form-button {
   padding: 14px 26px;
   border-radius: 4px;
   background: #00acef;
   border: 1px solid #00acef;
   color: white;
   font-size: 16px;
   line-height: 21px;
   cursor: pointer;
   transition: all 0.3s ease;
}

.show-password-btn {
   cursor: pointer;
}

.show-password-btn img {
   opacity: 0.5;
}

.show-password-btn_active img {
   opacity: 1;
}

.mainbar-add-manager-form-button:hover {
   background: #00a3e3;
}

.edit-btn {
   display: flex;
   align-items: center;
   gap: 5px;
}

.manager-permission-control {
   padding: 30px 30px 0 30px;
}

.manager-permission-control_list-block {
   gap: 40px;
   margin-bottom: 36px;
   display: grid;
   grid-template-columns: 1fr 0fr 1fr 0fr 1fr;
   grid-auto-flow: row;
   grid-gap: 20px 60px;
   /*height: 470px;*/
}

.manager-permission_list {
   margin-bottom: 34px;
   margin-top: 27px;
}

.manager-permission-control_list {
   display: flex;
   flex-direction: column;
   gap: 24px;
   height: 100%;
   width: 100%;
   position: relative;
}

.manager-permission-control-field {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 20px;
}

.manager-permission-control-field-name {
   font-style: normal;
   font-weight: 600;
   font-size: 20px;
   line-height: 24px;
}

.manager-permission-controller {
   flex: 0 0 36px;
   cursor: pointer;
   background-color: #E8E8E8;
   border-radius: 17px;
   width: 36px;
   height: 18px;
   margin-left: 5px;
   display: flex;
   align-items: center;
   padding: 3px;
}

.manager-permission-controller-active {
   flex: 0 0 36px;
   cursor: pointer;
   background-color: #40B7D9;
   border-radius: 17px;
   width: 36px;
   height: 18px;
   margin-left: 5px;
   display: flex;
   align-items: center;
   padding: 3px;
}

.manager-permission-field-name {
   margin-left: 5px;
   margin-right: auto;
   padding: 4px 8px;
   border-radius: 20px;
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 19px;
}

.manager-permission-field-name_active {
   background-color: rgba(222, 248, 255, 1);
}

.manager-permission-toggle-button {
   margin-right: auto;
   background: white;
   width: 14px;
   height: 14px;
   border-radius: 50%;
}

.manager-permission-toggle-button_active {
   margin-right: 0;
   margin-left: auto;
}

.manager-permission__footer-permissions {
   margin-bottom: 40px;
   display: flex;
   flex-direction: column;
   gap: 16px;
}

.manager-permission-control-dialogs-permission {
   max-width: 590px;
   display: flex;
   flex-direction: row;
   align-items: center;
   padding: 24px;
   gap: 64px;
   box-shadow: 0px 6px 20px rgba(213, 230, 235, 0.4);
   border-radius: 6px;
}

.manager-permission-control-text {
   width: 440px;
   font-style: normal;
   font-weight: 500;
   font-size: 16px;
   line-height: 140%;
}

.manager-permission-pagination {
   position: absolute;
   bottom: 0;
   right: 0;
}
