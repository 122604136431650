.chat-bot-words-group {
   display: grid;
   grid-template-columns: 15% 15% 15% 22.5% 12.5% 12.5% 7.5%;
}

.chat-bot-words-group-row {
   border: 1px solid #cccccc;
   display: flex;
   justify-content: center;
   align-items: center;
   font-size: 14px;
   padding: 10px;
   text-align: center;
}

.confirm-box__actions button {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   padding: 3px 6px;
   border-radius: 8px;
   background-color: #00acef;
   color: #fff;
   border: none;
   cursor: pointer;
   margin-right: 7px;
}

.confirm-box__actions button:nth-child(1) {
   background-color: #c70000;
}
