.mainbar-chat {
   position: relative;
   z-index: 10;
}

.mainbar-chat-attachments {
   padding: 20px 25px 0;
}

.mainbar-chat-area-wrap {
   position: relative;
   width: 100%;
   height: 100%;
}

.mainbar-chat-wrapper {
   padding: 11px 26px 15px 26px;
   display: flex;
   flex-direction: column;
   gap: 16px;
}

.mainbar-chat-wrapper__send-message-form {
   display: flex;
   justify-content: space-between;
   align-items: center;
   gap: 26px;
}

.mainbar-chat-left-control-buttons {
   display: flex;
   width: 100px;
   justify-content: space-between;
}

.mainbar-chat-clip {
   cursor: pointer;
   display: inline;
   position: absolute;
   top: 14px;
   left: 14px;
   z-index: 999;
}

.mainbar-chat-clip:disabled {
   opacity: .2;
}

.file-quantity {
   position: absolute;
   top: 16px;
   left: 8px;
   background-color: #4bade9;
   border-radius: 50%;
   height: 15px;
   width: 15px;
   text-align: center;
   display: flex;
   justify-content: center;
   align-content: center;
}

.file-quantity_number {
   font-size: 10px;
   color: white;
}

.recorder-button:disabled {
   opacity: .2;
}

.mainbar-chat-area-input-wrap {
   min-height: 55px;
   padding: 18px 80px 18px 46px;
   border-radius: 8px;
   width: 100%;
   background-color: #ffffff;
}

.mainbar-chat-area {
   width: 100%;
   max-height: 90px;
   background-color: #fff;
   border: none;
   outline: none;
   font-size: 14px;
   font-weight: 400;
   resize: none;
   color: #7f808c;
   line-height: 15px;
}

.mainbar-chat-area:disabled::placeholder {
   opacity: .2;
}

.mainbar-chat-textarea::-webkit-scrollbar {
   display: none;
}

.mainbar-chat-control-buttons {
   display: flex;
   align-items: center;
   gap: 26px;
}

.mainbar-chat-template-button {
   width: 32px;
   height: 32px;
   border-radius: 6px;
   background-color: #00acef;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
   border: none;
}

.mainbar-chat-send-button.btn {
   width: 34px;
   padding: 0;
}

.mainbar-chat-send-button:disabled {
   background-color: #E8E8E8;
}

.mainbar-chat-action-container {
   position: absolute;
}

.mainbar-chat-action-container_templates {
   right: 16px;
   top: 15px;
}

.mainbar-chat-action-container__btn {
   position: relative;
}

.mainbar-chat-action-container__tooltip {
   bottom: 0;
   left: 50%;
   transform: translate(-50%, -40px);
}

.mainbar-chat-action-container__btn button:hover ~ .mainbar-chat-action-container__tooltip {
   visibility: visible;
   opacity: 1;
}

.mainbar-chat-emoji {
   cursor: pointer;
   height: 24px;
   width: 24px;
   border: none;
   background-color: transparent;
}

.mainbar-chat-emoji:disabled {
  opacity: .2;
}

.mainbar-chat-control-buttons,
.mainbar-chat-template-button {
   cursor: pointer;
}

.dialog-chat-timer {
   position: absolute;
   bottom: calc(100% + 12px);
   left: 50%;
   transform: translateX(-50%);
}

@media (max-width: 900px) {
   .mainbar-chat {
      width: 100% !important;
   }
}