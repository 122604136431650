.reply-section {
   display: flex;
   align-items: center;
   gap: 8px;
}

.reply-section::before {
   content: "";
   display: block;
   flex: 0 0 auto;
   width: 4px;
   min-height: 50px;
   border-radius: 2px;
   background-color: #158CAE;
}

.reply-section__body {
   flex: 1 1 auto;
   display: flex;
   gap: 8px;
   align-items: center;
   overflow: hidden;
}

.reply-section__image {
   width: 38px;
   height: 38px;
   border-radius: 5px;
   flex: 0 0 auto;
   overflow: hidden;
   position: relative;
}

.reply-section__image img {
   position: absolute;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   object-fit: cover;
}

.reply-section__info {
   flex: 1 1 auto;
   display: flex;
   flex-direction: column;
   gap: 2px;
   font-size: 14px;
   overflow: hidden;
}

.reply-section__title {
   font-weight: 500;
   line-height: 16.94px;
   color: #158CAE;
}

.reply-section__text {
   line-height: 19.04px;
   white-space: nowrap;
   text-overflow: ellipsis;
   overflow: hidden;
}

.reply-section__close-btn {
   flex: 0 0 auto;
   display: flex;
   align-items: center;
   cursor: pointer;
}

.reply-section__close-btn svg,
.reply-section__close-btn path {
   width: 24px;
   height: 24px;
   fill: rgba(37, 37, 37, 0.5);
}
