.sidebar-header-search-container {
   flex: 1 1 auto;
}

.sidebar-header-search-field {
   position: relative;
}

.sidebar-header-search-field-icon {
   position: absolute;
   top: 9px;
   left: 20px;
}

.sidebar-header-search-field-input {
   height: 36px;
   width: 100%;
   border-radius: 6px;
   outline: none;
   padding-left: 8px;
   text-indent: 46px;
   background-color: #ffffff;
   outline: none;
   border-color: #ffffff;
   border: none;
}

.sidebar-header-search-field-input:placeholder-shown {
   font-size: 14px;
}