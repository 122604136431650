.mainbar-conversations-system-message {
   max-width: 545px;
   height: 62px;
   border-radius: 8px;
   margin: 0 auto;
   background: #F8F8F8;
   font-size: 12px;
   line-height: 16px;
   text-align: center;
   padding: 13px 25px;
}