.tag-item {
   list-style: none;
   display: flex;
}

.tag-item_block {
   /*width: 230px;*/
   display: flex;
   align-items: center;
   gap: 14px;
   padding: 8px 22px;
   border-radius: 100px;
   background: #edf7fa;
}

.tag-item_title {
   color: #158cae;
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 17px;
}

.tag-item-icon {
   cursor: pointer;
}

@media (max-width: 900px) {
   .tag-item_title {
      font-size: 16px;
      line-height: 19px;
   }
}
