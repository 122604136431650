/* Roboto font */
@font-face {
   font-display: block;
   font-family: 'Roboto';
   src: url(./fonts/roboto.italic-webfont.woff2) format('woff2'),
      url(./fonts/roboto.italic-webfont.woff) format('woff');
   font-weight: 400;
   font-style: italic;
   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
}

@font-face {
   font-display: block;
   font-family: 'Roboto';
   src: url(./fonts/roboto.bold-webfont.woff2) format('woff2'),
      url(./fonts/roboto.bold-webfont.woff) format('woff');
   font-weight: 700;
   font-style: normal;
   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
}

@font-face {
   font-display: block;
   font-family: 'Roboto';
   src: url(./fonts/roboto.regular-webfont.woff2) format('woff2'),
      url(./fonts/roboto.regular-webfont.woff) format('woff');
   font-weight: 400;
   font-style: normal;
   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
}

@font-face {
   font-display: block;
   font-family: 'Roboto';
   src: url(./fonts/roboto.medium-webfont.woff2) format('woff2'),
      url(./fonts/roboto.medium-webfont.woff) format('woff');
   font-weight: 500;
   font-style: normal;
   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
}
/* Roboto font end*/

/* Inter font */
@font-face {
   font-family: 'Inter';
   font-style:  normal;
   font-weight: 100;
   font-display: swap;
   src: url(./fonts/inter/Inter-Thin.woff2) format("woff2"),
   url(./fonts/inter/Inter-Thin.woff) format("woff");
}
@font-face {
   font-family: 'Inter';
   font-style:  italic;
   font-weight: 100;
   font-display: swap;
   src: url(./fonts/inter/Inter-ThinItalic.woff2) format("woff2"),
   url(./fonts/inter/Inter-ThinItalic.woff) format("woff");
}

@font-face {
   font-family: 'Inter';
   font-style:  normal;
   font-weight: 200;
   font-display: swap;
   src: url(./fonts/inter/Inter-ExtraLight.woff2) format("woff2"),
   url(./fonts/inter/Inter-ExtraLight.woff) format("woff");
}
@font-face {
   font-family: 'Inter';
   font-style:  italic;
   font-weight: 200;
   font-display: swap;
   src: url(./fonts/inter/Inter-ExtraLightItalic.woff2) format("woff2"),
   url(./fonts/inter/Inter-ExtraLightItalic.woff) format("woff");
}

@font-face {
   font-family: 'Inter';
   font-style:  normal;
   font-weight: 300;
   font-display: swap;
   src: url(./fonts/inter/Inter-Light.woff2) format("woff2"),
   url(./fonts/inter/Inter-Light.woff) format("woff");
}
@font-face {
   font-family: 'Inter';
   font-style:  italic;
   font-weight: 300;
   font-display: swap;
   src: url(./fonts/inter/Inter-LightItalic.woff2) format("woff2"),
   url(./fonts/inter/Inter-LightItalic.woff) format("woff");
}

@font-face {
   font-family: 'Inter';
   font-style:  normal;
   font-weight: 400;
   font-display: swap;
   src: url(./fonts/inter/Inter-Regular.woff2) format("woff2"),
   url(./fonts/inter/Inter-Regular.woff) format("woff");
}
@font-face {
   font-family: 'Inter';
   font-style:  italic;
   font-weight: 400;
   font-display: swap;
   src: url(./fonts/inter/Inter-Italic.woff2) format("woff2"),
   url(./fonts/inter/Inter-Italic.woff) format("woff");
}

@font-face {
   font-family: 'Inter';
   font-style:  normal;
   font-weight: 500;
   font-display: swap;
   src: url(./fonts/inter/Inter-Medium.woff2) format("woff2"),
   url(./fonts/inter/Inter-Medium.woff) format("woff");
}
@font-face {
   font-family: 'Inter';
   font-style:  italic;
   font-weight: 500;
   font-display: swap;
   src: url(./fonts/inter/Inter-MediumItalic.woff2) format("woff2"),
   url(./fonts/inter/Inter-MediumItalic.woff) format("woff");
}

@font-face {
   font-family: 'Inter';
   font-style:  normal;
   font-weight: 600;
   font-display: swap;
   src: url(./fonts/inter/Inter-SemiBold.woff2) format("woff2"),
   url(./fonts/inter/Inter-SemiBold.woff) format("woff");
}
@font-face {
   font-family: 'Inter';
   font-style:  italic;
   font-weight: 600;
   font-display: swap;
   src: url(./fonts/inter/Inter-SemiBoldItalic.woff2) format("woff2"),
   url(./fonts/inter/Inter-SemiBoldItalic.woff) format("woff");
}

@font-face {
   font-family: 'Inter';
   font-style:  normal;
   font-weight: 700;
   font-display: swap;
   src: url(./fonts/inter/Inter-Bold.woff2) format("woff2"),
   url(./fonts/inter/Inter-Bold.woff) format("woff");
}
@font-face {
   font-family: 'Inter';
   font-style:  italic;
   font-weight: 700;
   font-display: swap;
   src: url(./fonts/inter/Inter-BoldItalic.woff2) format("woff2"),
   url(./fonts/inter/Inter-BoldItalic.woff) format("woff");
}

@font-face {
   font-family: 'Inter';
   font-style:  normal;
   font-weight: 800;
   font-display: swap;
   src: url(./fonts/inter/Inter-ExtraBold.woff2) format("woff2"),
   url(./fonts/inter/Inter-ExtraBold.woff) format("woff");
}
@font-face {
   font-family: 'Inter';
   font-style:  italic;
   font-weight: 800;
   font-display: swap;
   src: url(./fonts/inter/Inter-ExtraBoldItalic.woff2) format("woff2"),
   url(./fonts/inter/Inter-ExtraBoldItalic.woff) format("woff");
}

@font-face {
   font-family: 'Inter';
   font-style:  normal;
   font-weight: 900;
   font-display: swap;
   src: url(./fonts/inter/Inter-Black.woff2) format("woff2"),
   url(./fonts/inter/Inter-Black.woff) format("woff");
}
@font-face {
   font-family: 'Inter';
   font-style:  italic;
   font-weight: 900;
   font-display: swap;
   src: url(./fonts/inter/Inter-BlackItalic.woff2) format("woff2"),
   url(./fonts/inter/Inter-BlackItalic.woff) format("woff");
}

/* -------------------------------------------------------
Variable font.
Usage:

  html { font-family: 'Inter', sans-serif; }
  @supports (font-variation-settings: normal) {
    html { font-family: 'Inter var', sans-serif; }
  }
*/
@font-face {
   font-family: 'Inter var';
   font-weight: 100 900;
   font-display: swap;
   font-style: normal;
   font-named-instance: 'Regular';
   src: url(./fonts/inter/Inter-roman.var.woff2) format("woff2");
}
@font-face {
   font-family: 'Inter var';
   font-weight: 100 900;
   font-display: swap;
   font-style: italic;
   font-named-instance: 'Italic';
   src: url(./fonts/inter/Inter-italic.var.woff2) format("woff2");
}


/* --------------------------------------------------------------------------
[EXPERIMENTAL] Multi-axis, single variable font.

Slant axis is not yet widely supported (as of February 2019) and thus this
multi-axis single variable font is opt-in rather than the default.

When using this, you will probably need to set font-variation-settings
explicitly, e.g.

  * { font-variation-settings: "slnt" 0deg }
  .italic { font-variation-settings: "slnt" 10deg }

*/
@font-face {
   font-family: 'Inter var experimental';
   font-weight: 100 900;
   font-display: swap;
   font-style: oblique 0deg 10deg;
   src: url(./fonts/inter/Inter.var.woff2) format("woff2");
}
/* Inter font end */

* {
   box-sizing: border-box;
   margin: 0;
   padding: 0;
   font-family: 'Roboto', sans-serif;
}

img,
video {
   max-width: 100%;
   max-height: 100%;
   margin: 0 auto;
}
