.time-without-answer-form {
   display: flex;
   flex-direction: column;
   gap: 32px;
}

.time-without-answer-form__control {
   display: flex;
   flex-direction: column;
   gap: 24px;
}