.dialog-settings {
   height: 100vh;
   display: flex;
   flex-direction: column;
}

.dialog-settings-wrapper {
   flex: 1 1 auto;
   padding: 30px;
}

.dialog-settings__title {
   font-weight: 700;
   font-size: 28px;
   line-height: 33.89px;
   color: rgba(37, 37, 37, 1);
}

.dialog-settings__title:not(:last-child) {
   margin-bottom: 28px;
}

.dialog-settings__settings-list {
   display: flex;
   flex-direction: column;
   list-style: none;
   gap: 30px;
}

.dialog-settings__settings-list-item {
   display: flex;
   flex-direction: column;
}

.setting-item__controller {
   flex: 0 0 36px;
}

.setting-item__controller-toggle-btn_active {
   background-color: rgba(64, 183, 217, 1);
}

.setting-item__controller-toggle-btn_active .setting-item__controller-toggle-btn {
   margin-right: 0;
   margin-left: auto;
}

.setting-item__controller-info {
   max-width: 465px;
   display: flex;
   flex-direction: column;
   gap: 8px;
}

.setting-item__controller-name {
   font-weight: 400;
   font-size: 16px;
   line-height: 20px;
   color: rgba(37, 37, 37, 1);
}

.setting-item__controller-description {
   font-size: 12px;
   line-height: 16.8px;
   color: rgba(140, 148, 151, 1);
}

.setting-item {
   display: flex;
   align-items: center;
   gap: 15px;
}

.setting-item_auto-read .setting-item__controller {
   align-self: flex-start;
}

@media (max-width: 900px) {
   .dialog-settings {
      width: auto;
   }

   .dialog-settings-wrapper {
      padding: 20px 50px 30px;
   }
}

.time-without-answer-setting {
   align-self: flex-start;
   padding: 12px 16px;
   border-radius: 12px;
   display: flex;
   flex-direction: column;
   gap: 8px;
   background-color: #F8F8F8;
}

.time-without-answer-setting__title {
   font-size: 12px;
   line-height: 1.3;
   color: #8C9497;
}

.time-without-answer-setting__group {
   display: flex;
   flex-direction: column;
   gap: 16px;
}

.time-without-answer-setting__btn {
   padding: 7px;
}