.message-status-btn {
   display: flex;
   align-items: center;
   border: none;
   background: none;
   width: 15px;
   height: 15px;
}

.message-status path {
   stroke: rgba(37, 37, 37, 0.7);
}

.message-status.message-status_read path {
   stroke: #4FC6E8;
}

.message-status_outgoing path {
   stroke: rgba(174, 227, 242, 1);
}

.message-status_outgoing.message-status_read path {
   stroke: rgba(255, 255, 255, 1);
}