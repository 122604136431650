.warning {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: rgba(0, 0, 0, 0.2);
   z-index: 999;
}

.warning-enter {
   opacity: 0;
}

.warning-enter-active {
   opacity: 1;
   transition: opacity 500ms;
}

.warning.exit {
   opacity: 1;
}

.warning-exit-active {
   opacity: 0;
   transition: opacity 500ms;
}

.warning_wrapper {
   display: flex;
   flex-direction: column;
   align-items: center;
   position: fixed;
   background: #fff;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   padding: 50px 50px;
   border-radius: 6px;
   box-shadow: 0px 6px 50px rgba(180, 185, 187, 0.6);
}

.warning_header {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
}
.warning_title {
   text-align: center;
   font-style: normal;
   font-weight: 600;
   font-size: 22px;
   line-height: 136%;
   margin-bottom: 18px;
}

.warning_text {
   max-width: 490px;
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 140%;
   text-align: center;
   margin-bottom: 30px;
}

.warning_buttons {
   display: flex;
   gap: 26px;
}

.warning_button {
   padding: 14px 26px;
   box-shadow: 0 6px 20px rgba(108, 197, 222, 0.2);
   border-radius: 6px;
   cursor: pointer;
}

.yes {
   background: #40b7d9;
   color: #ffffff;
   border: none;
}

.yes:hover {
   background-color: #4fc6e8;
}

.yes:active {
   background-color: #39b0d2;
}

.no {
   border: 1px solid #40b7d9;
   background: #ffffff;
   color: #40b7d9;
}

.no:hover {
   background-color: #4fc6e8;
   color: #ffffff;
}

.no:active {
   background-color: #39b0d2;
}
@media (max-width: 900px) {
   .warning_wrapper {
      width: 85% !important;
      padding: 30px 18px;
   }

   .warning_title {
      font-size: 20px;
      line-height: 138%;
      text-align: center;
   }

   .warning_text {
      font-size: 14px;
      line-height: 140%;
   }
}
