.template-list-wrapper {
   background-color: #fff;
   box-shadow: 0px 6px 26px rgba(73, 154, 228, 0.16);
   border-radius: 6px;
   position: fixed;
   bottom: 85px;
   right: 175px;
   width: 270px;
   padding-top: 10px;
   padding-bottom: 10px;
   z-index: 999;
}

@media screen and (max-width: 959.98px) and (min-width: 901px) {
   .template-list-wrapper {
      right: 135px;
   }
}

@media screen and (max-width: 471.98px) {
   .template-list-wrapper {
      left: 26px;
   }
}

.template-list-wrapper::-webkit-scrollbar {
   display: block;
   width: 2px;
}

.template-list-wrapper::-webkit-scrollbar-thumb {
   background-color: #40b7d9;
}

.template-list-wrapper::-webkit-scrollbar-track {
   background-color: #f0f0f0;
}

.template-list__list {
   list-style: none;
   max-height: 200px;
   overflow-y: auto;
}

.template-list-item {
   background: #ffffff;
   display: flex;
   flex-direction: column;
   font-style: normal;
   font-weight: 500;
   font-size: 14px;
   line-height: 17px;
   white-space: pre-wrap;
   text-overflow: ellipsis;
   border-radius: 0px 6px 6px 0px;
   transition: all 0.3s ease;
}

.template-list-item__subsection {
   width: 100%;
   min-height: 30px;
   padding: 0px 20px;
   display: flex;
   justify-content: space-between;
   gap: 10px;
   align-items: center;
   text-align: start;
   cursor: pointer;
   overflow: hidden;
   text-overflow: ellipsis;
   text-wrap: nowrap;
}

.template-list-item__subsection-icon {
   margin: 0;
   transform: rotate(180deg);
}

.template-list-item_active .template-list-item__subsection-icon {
   transform: rotate(0deg);
}

.template-list-item__sublist {
   display: none;
   background-color: #fff;
   border: 1px solid rgba(228, 228, 228, 1);
   border-left: none;
   border-right: none;
   width: 100%;
   left: calc(-100% - 5px);
   bottom: 0;
   max-height: 90px;
   z-index: 10;
   overflow: auto;
   visibility: hidden;
   opacity: 0;
   transition: all 0.3s ease;
}

.template-list-item__sublist_show {
   display: block;
   visibility: visible;
   opacity: 1;
}

.template-list-item__sublist::-webkit-scrollbar {
   display: block;
   width: 2px;
}

.template-list-item__sublist::-webkit-scrollbar-thumb {
   background-color: #40b7d9;
}

.template-list-item__sublist::-webkit-scrollbar-track {
   background-color: #f0f0f0;
}

.template-list-item__sublist-message {
   cursor: default;
}

.template-list-item__sublist-item {
   padding: 6px 20px;
   overflow: hidden;
   text-overflow: ellipsis;
   text-wrap: nowrap;
}

.template-list-item__sublist-message {
   padding: 6px 20px;
}

.template-list-item__sublist-item:hover {
   background: #def8ff;
   cursor: pointer;
}