.forgot-password-page__card {
   max-width: 600px;
}

.forgot-password-page__wrapper {
   display: flex;
   flex-direction: column;
   gap: 32px;
}

.forgot-password-page__header-wrapper:not(:last-child) {
   margin-bottom: 38px;
}

.forgot-password-page__header {
   display: flex;
   flex-direction: column;
   gap: 20px;
}

.forgot-password-page__header-title {
   position: relative;
}

.forgot-password-page__return-btn {
   position: absolute;
   top: 50%;
   left: 0;
   transform: translateY(-50%);
   width: 32px;
   height: 32px;
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;
}

.forgot-password-page__return-btn img {
   margin: 0;
}

.forgot-password-page__header-text {
   font-weight: 600;
   font-size: 28px;
   line-height: 32px;
   text-align: center;
}

.forgot-password-page__description {
   font-size: 16px;
   line-height: 20px;
   text-align: center;
}

.forgot-password-page__description span {
   color: #00ACEF;
   text-decoration: none;
}

.forgot-password-form {
   display: flex;
   flex-direction: column;
   gap: 32px;
}

.forgot-password-form__status {
   text-align: center;
   color: #d94040;
}

.forgot-password-form-control {
   display: flex;
   flex-direction: column;
   gap: 4px;
}

.forgot-password-form-control__label {
   font-weight: 400;
   font-size: 12px;
   line-height: 16px;
   color: rgba(37, 37, 37, 1);
}

.forgot-password-form-control__label_error {
   color: #d94040;
}

.forgot-password-form-input {
   height: auto;
   padding: 11px 16px;
   border: 1px solid rgba(228, 228, 228, 1);
   border-radius: 12px;
   font-weight: 400;
   font-size: 14px;
   line-height: 18px;
}

.forgot-password-form__submit-btn {
   border-radius: 12px;
}

.forgot-password-form__submit-btn:disabled {
   background-color: rgba(228, 228, 228, 1);
}

@media screen and (max-width: 575.99px) {
   .forgot-password-page__wrapper {
      gap: 20px;
   }

   .forgot-password-page__header {
      gap: 16px;
   }

   .forgot-password-page__return-btn {
      flex: 0 0 24px;
      width: 24px;
      height: 24px;
   }

   .forgot-password-page__return-btn img {
      max-width: 8px;
   }

   .forgot-password-page__header-text {
      font-size: 20px;
      line-height: 24px;
   }

   .forgot-password-page__description {
      font-size: 14px;
      line-height: 18px;
   }

   .forgot-password-form {
      gap: 20px;
   }
}