.mailing-wrapper {
   margin-left: 28px;
   padding: 32px 90px 32px 32px;
   overflow: auto;
}

@media screen and (max-width: 900px) {
   .mailing-wrapper {
      width: calc(100% - 93px);
      margin-left: 93px;
   }
}

.mailing-header {
   position: relative;
   min-height: 44px;
   display: flex;
   align-items: center;
}

.mailing-title {
   font-weight: 600;
   font-size: 28px;
   line-height: 32px;
}

.mailing-type-select {
   position: absolute;
   z-index: 10;
   top: 0;
   right: 0;
   padding: 8px 4px;
   border-radius: 8px;
   display: flex;
   flex-direction: column;
   gap: 8px;
}

.mailing-type-select.active {
   background-color: white;
   box-shadow: 0 6px 20px rgba(213, 230, 235, 0.4);
}

.mailing-type-select__btn {
   min-width: 230px;
   min-height: 44px;
   padding: 12px 20px;
   border-radius: 8px;
   display: flex;
   align-items: center;
   gap: 8px;
   background-color: rgba(64, 183, 217, 1);
   box-shadow: 0 6px 20px 0 rgba(108, 197, 222, 0.2);
   font-weight: 500;
   font-size: 16px;
   line-height: 20px;
   color: white;
   cursor: pointer;
}

.mailing-type-select__btn-icon {
   transform: rotate(180deg);
}

.mailing-type-select__btn-icon path {
   fill: white;
}

.mailing-type-select__list {
   display: none;
   list-style: none;
}

.mailing-type-select.active .mailing-type-select__list {
   display: flex;
   flex-direction: column;
   gap: 8px;
}

.mailing-type-select__list-item {
   padding: 8px 12px;
   border-radius: 8px;
   font-weight: 500;
   font-size: 14px;
   line-height: 18px;
}

.mailing-type-select__list-item:hover {
   background-color: rgba(222, 248, 255, 1);
   cursor: pointer;
}

.mailing-header_navigation {
   display: flex;
   flex-direction: row;
   gap: 100px;
   margin-bottom: 28px;
}

.mailing-header_navigation-button {
   border: none;
   background-color: white;
   font-style: normal;
   font-weight: 700;
   font-size: 28px;
   line-height: 34px;
   padding-bottom: 17px;
   cursor: pointer;
}

.mailing-header_navigation-button-active {
   border: none;
   background-color: white;
   font-style: normal;
   font-weight: 700;
   font-size: 28px;
   line-height: 34px;
   padding-bottom: 17px;
   border-bottom: 1px solid #00acef;
   cursor: pointer;
}

.mailing-add-button {
   fill: white;
   display: flex;
   flex-direction: row;
   align-items: center;
   gap: 8px;
   background: #40b7d9;
   box-shadow: 0px 6px 20px rgba(108, 197, 222, 0.2);
   padding: 14px 26px;
   border-radius: 6px;
   border: none;
   color: white;
   font-size: 16px;
   line-height: 21px;
   cursor: pointer;
}

.mailing-add-button:hover {
   background-color: #4fc6e8;
}

.mailing-add-button:active {
   background-color: #39b0d2;
}

.mailing-history-list {
}

.mailing-history-list-advance {
   box-shadow: 0px 6px 26px rgba(211, 223, 228, 0.4);
   border-radius: 4px;
   width: 200px;
   height: 130px;
   overflow: auto;
   position: absolute;
   top: 90px;
   background-color: white;
   padding: 8px;
}

.show-more-button {
   display: flex;
   border: none;
   background-color: white;
   color: #40b7d9;
}

.mailing-history-list-advance::-webkit-scrollbar {
   display: block;
}

.mailing-history-header {
   list-style: none;
}

.mailing-mailing-history-item {
   list-style: none;
}

.mailing-history-item-attachments-list {
   max-width: 110px;
   height: 100%;
   list-style: none;
   max-height: 190px;
   display: flex;
   flex-direction: column;
   gap: 5px;
   overflow-y: auto;
}

.mailing-history-item-attachments-list::-webkit-scrollbar {
   display: block;
   width: 2px;
}

.mailing-history-item-attachments-list::-webkit-scrollbar-track,
.mailing-history-item-attachments-list::-webkit-scrollbar-thumb {
   border-radius: 2px;
}

.mailing-history-item-attachments-list .template-item_file-ref {
   display: block;
   text-wrap: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

.mailing-history-item-attachments-list .template-item_file-ref:hover {
   color: #158cae;
}

.mailing-history-header_block {
   width: 100%;
   display: grid;
   grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
   grid-auto-flow: row;
   grid-gap: 10px 30px;
   font-size: 14px;
   line-height: 17px;
   font-weight: 600;
   color: #252525;
   opacity: 0.4;
   padding: 24px 30px;
}

.mailing-history-item-block {
   width: 100%;
   display: grid;
   grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
   grid-auto-flow: row;
   grid-gap: 10px 30px;
   font-size: 14px;
   line-height: 17px;
   color: #252525;
   border-top: 1px solid #e4e4e4;
   padding: 24px 30px;
}

.mailing-history-block {
}

.mailing-history-title {
}

.mailing-history-user-block {
}

.mailing-history-user-block_inactive .mailing-history-name {
   color: #8C9497;
}

.mailing-history-user-wrapper {
   display: flex;
   align-items: center;
   gap: 10px;
}

.mailing-history-avatar {
   width: 30px;
   height: 30px;
   margin: 0;
   border-radius: 50%;
}

.mailing-history-user-wrapper__user-info {
   display: flex;
   flex-direction: column;
}

.mailing-history-user-wrapper__status {
   align-self: flex-start;
   font-weight: 400;
   font-size: 12px;
   line-height: 12px;
   color: #ED5656;
}

.mailing-history-list_item {
   list-style: none;
   display: flex;
}

.mailing-history-channels-block {
   display: flex;
   flex-direction: column;
   position: relative;
}

.mailing-history-channel-item {
   font-weight: 500;
   font-size: 14px;
   line-height: 17px;
   margin-bottom: 8px;
}

.mailing-history-statuses-block {
   display: flex;
   flex-direction: column;
   position: relative;
}

.mailing-history-statuses {
   border-radius: 6px;
   color: white;
   margin-bottom: 8px;
   padding: 4px 16px;
   display: flex;
   align-items: center;
}

.mailing-history-tags-block {
   display: flex;
   flex-direction: column;
   position: relative;
}

.mailing-history-tags {
   border-radius: 100px;
   background-color: #f1fbfc;
   padding: 4px 8px;
   margin-bottom: 8px;
   color: #158cae;
   display: flex;
   justify-content: center;
}

.mailing-history-text-block {
   height: 190px;
   white-space: pre-wrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

.mailing-history-file-block {
   word-break: break-word;
}

.mailing-history-time-block {
   display: flex;
   gap: 6px;
}

.mailing-history-empty{
   font-weight: 500;
   font-size: 14px;
   line-height: 17px;
   margin-bottom: 8px;
   background-color: #e0e0e0;
   padding: 2px 8px;
   border-radius: 3px;
   display: inline-block;
}