.edit-manager-form {
   display: flex;
   flex-direction: column;
   gap: 22px;
}

.edit-manager-form__status {
   margin-bottom: 20px;
   color: #d94040;
}

.edit-manager-form__footer {
   display: flex;
   gap: 30px;
}

@media screen and (max-width: 1151.98px) {
   .edit-manager-form__footer {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
   }
}

.edit-manager-form__btn_delete {
   flex-direction: row-reverse;
}

.edit-manager-form-control {
   max-width: 284px;
   display: flex;
   flex-direction: column;
   color: #252525;
}

.edit-manager-form-control_active .edit-manager-form-control__body {
   border-color: #40B7D9;
}

.edit-manager-form-control__label {
   font-weight: 400;
   font-size: 12px;
   line-height: 14.52px;
   color: inherit;
   margin-bottom: 8px;
}

.edit-manager-form-control__label_error {
   color: #d94040;
}

.edit-manager-form-control__body {
   padding: 14px 20px;
   display: flex;
   align-items: center;
   gap: 5px;
   border: 1px solid #E4E4E4;
   border-radius: 6px;
}

.edit-manager-form-control__input {
   width: 100%;
   flex: 1 1 100%;
   border: none;
   background-color: transparent;
   font-weight: 400;
   font-size: 14px;
   line-height: 16.94px;
   color: #252525;
}

.edit-manager-form-control__input:focus {
   outline: none;
}

.edit-manager-form-control__btn {
   display: flex;
   align-items: center;
}

.edit-manager-form-control__btn:hover {
   cursor: pointer;
}

.edit-manager-form-control__show-btn {
   opacity: 0.5;
}

.edit-manager-form-control__show-btn_active {
   opacity: 1;
}

.edit-manager-form-radio-control__label {
   margin-bottom: 8px;
   display: flex;
   align-items: center;
   gap: 2px;
   font-weight: 400;
   font-size: 12px;
   line-height: 14.52px;
}

.edit-manager-form-radio-control__warning-btn {
   display: flex;
   align-items: center;
}

.edit-manager-form-radio-control__warning-btn:hover {
   cursor: pointer;
}

.edit-manager-form-radio-control__body {
   display: flex;
   gap: 12px;
}

.edit-manager-form-radio-btn__body {
   display: flex;
   align-items: center;
   gap: 4px;
}

.edit-manager-form-radio-btn__body:hover {
   cursor: pointer;
}

.edit-manager-form-radio-btn__input {
   display: none;
}

.edit-manager-form-radio-btn__radio {
   width: 20px;
   height: 20px;
   border: 1px solid #E8E8E8;
   border-radius: 50%;
   display: flex;
   justify-content: center;
   align-items: center;
}

.edit-manager-form-radio-btn__radio::before {
   content: "";
   width: 10px;
   height: 10px;
   border-radius: 50%;
   background-color: white;
}

.edit-manager-form-radio-btn_active .edit-manager-form-radio-btn__radio {
   border-color: #40B7D9;
   background-color: #40B7D9;
}