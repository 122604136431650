.mass-send {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: rgba(0, 0, 0, 0.6);
   z-index: 999;
}

.mass-send-wrapper {
   position: fixed;
   background: #fff;
   width: 572px;
   height: auto;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   padding: 32px;
   border-radius: 16px;
   position: relative;
}

.mass-send-form-control {
   display: flex;
   justify-content: space-between;
   margin-bottom: 10px;
}

.mass-send-form-label {
   font-size: 16px;
   line-height: 24px;
   margin-right: 20px;
   display: inline;
}

.mass-send-text-form {
   resize: none;
   height: 138px;
   width: 360px !important;
   display: inline-block;
   border: none;
   outline: none;
   border: 1px solid #cccccc;
   border-radius: 4px;
   padding: 6px 10px;
   font-size: 16px;
   line-height: 21px;
}

.mass-send-channel-type-container {
   width: 360px !important;
   height: 38px !important;
}

.mass-send-contact-list {
   width: 243px !important;
   border: none;
   outline: none;
   border: 1px solid #cccccc;
   border-radius: 4px;
   height: 38px;
   padding: 0 10px;
   margin-right: 10px;
}

.mass-send-add-contact-button {
   width: 107px;
   height: 38px;
   border-radius: 4px;
   background: #d2d2d2;
   color: #fff;
   border: 1px solid #d2d2d2;
   font-size: 16px;
   line-height: 21px;
   cursor: pointer;
}

.mass-send-add-contact-button {
   background: #00acef;
   border: 1px solid #00acef;
}

.mass-send-add-contact-toggle-button {
   width: 175px;
   min-height: 38px;
   color: #fff;
   font-size: 16px;
   line-height: 21px;
   background: #00acef;
   border: 1px solid #00acef;
   box-sizing: border-box;
   border-radius: 4px;
   cursor: pointer;
}

.mass-send-single-contact-field {
   background-color: #e0e0e0;
   margin-right: 5px;
   padding: 2px 0 2px 8px;
   border-radius: 3px;
   font-size: 16px;
   cursor: default;
   width: auto;
   margin-bottom: 5px;
}

.mass-send-contact-list-display {
   display: flex;
   flex-wrap: wrap;
}

.mass-send-form-submit-button {
   width: 192px;
   height: 38px;
   color: #fff;
   font-size: 16px;
   line-height: 21px;
   background: #00acef;
   border: 1px solid #00acef;
   box-sizing: border-box;
   border-radius: 4px;
   cursor: pointer;
}

.mass-send-loader {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100%;
   width: 100%;
}

.mass-send-instructions {
   position: absolute;
   width: 225px;
   right: -205px;
   top: 240px;
   background-color: white;
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.205);
   padding: 15px;
   border-radius: 6px;
}

.mass-send-contact-control-buttons {
   width: 360px;
   display: grid;
   grid-template-columns: 175px 175px;
   grid-column-gap: 10px;
   font-size: 13px;
}

.lds-ring {
   display: inline-block;
   position: relative;
   width: 80px;
   height: 80px;
}

.lds-ring div {
   box-sizing: border-box;
   display: block;
   position: absolute;
   width: 64px;
   height: 64px;
   margin: 8px;
   border: 8px solid #6eafcf;
   border-radius: 50%;
   animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
   border-color: #6eafcf transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
   animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
   animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
   animation-delay: -0.15s;
}

@keyframes lds-ring {
   0% {
      transform: rotate(0deg);
   }

   100% {
      transform: rotate(360deg);
   }
}

@media (max-width: 900px) {
   .mass-send-wrapper {
      width: 95%;
   }

   .mass-send-form-control {
      display: block;
   }

   .mass-send-channel-type-container {
      width: 100% !important;
   }

   .mass-send-text-form {
      width: 100% !important;
   }

   .mass-send-contact-control-buttons {
      width: 100%;
      grid-template-columns: auto auto;
   }

   .mass-send-add-contact-toggle-button {
      width: 100%;
   }

   .mass-send-form-submit {
      margin: 0 !important;
   }

   .mass-send-form-submit-button {
      margin: 0;
      width: 100%;
   }
}
