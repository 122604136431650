.onboarding-tooltip {
   max-width: 494px;
   min-width: 300px;
   padding: 24px;
   border-radius: 6px;
   background-color: white;
   position: relative;
   color: #252525;
}

.onboarding-tooltip__content {
   padding: 26px 26px 0;
   margin-bottom: 40px;
}

.onboarding-tooltip__title {
   margin-bottom: 16px;
   font-weight: 600;
   font-size: 20px;
   line-height: 24.2px;
   text-align: center;
   color: inherit;
}

.onboarding-tooltip__text {
   font-weight: 400;
   font-size: 16px;
   line-height: 22.4px;
   text-align: center;
   color: inherit;
}

.onboarding-tooltip__footer {
   display: flex;
   align-items: center;
   gap: 24px;
}

.onboaring-tooltip__btn {
   min-height: 48px;
   display: flex;
   justify-content: center;
   align-items: center;
   font-weight: 600;
   font-size: 16px;
   line-height: 16px;
   color: #40B7D9;
   outline: none;
}

.onboaring-tooltip__btn:hover {
   cursor: pointer;
}

.onboaring-btn-skip {
   margin-right: auto;
}

.onboaring-btn-next {
   padding: 0 32px;
   border-radius: 6px;
   background-color: #40B7D9;
   color: white;
}

.onboaring-btn-close {
   display: flex;
   padding: 7px;
   position: absolute;
   top: 8px;
   right: 8px;
}

.onboaring-btn-close:hover {
   cursor: pointer;
}

.onboaring-btn-close__body {
   width: 14px;
   height: 14px;
   position: relative;
}

.onboaring-btn-close__body::before,
.onboaring-btn-close__body::after {
   content: '';
   display: block;
   width: 14px;
   height: 1px;
   background-color: #252525;
   position: absolute;
   top: 50%;
}

.onboaring-btn-close__body::before {
   top: 50%;
   transform: translateY(-50%) rotate(45deg);
}

.onboaring-btn-close__body::after {
   top: 50%;
   transform: translateY(-50%) rotate(-45deg);
}