.mainbar-sidebar-toggle-left-button {
   position: absolute;
   top: 15px;
   left: 10px;
   z-index: 101;
   height: 25px;
   width: 25px;
   display: none;
}

.mainbar-sidebar-toggle-left-button_show {
   display: block;
}

.mainbar-sidebar-toggle-left-button img {
   position: absolute;
   top: 6px;
   cursor: pointer;
   filter: invert(0.7)
}