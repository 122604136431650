.dialog-answer-timer {
   min-height: 24px;
   padding: 4px 8px;
   border-radius: 12px;
   display: flex;
   gap: 6px;
   background-color: #F8F8F8;
   font-size: 12px;
   line-height: 1.3;
   color: #8C9497;
}

.dialog-answer-timer_overdue {
   background-color: #FFCCCC;
   color: #ED5656;
}

.dialog-answer-timer_overdue svg path {
   fill: #ED5656;
}