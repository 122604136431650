.mailing-notification-file {
   margin-bottom: 12px;
   font-size: 16px;
   color: #8c9497;
}

.mailing-notification-button {
   width: 337px;
   padding: 14px 26px;
   box-shadow: 0 6px 20px rgba(108, 197, 222, 0.2);
   border-radius: 6px;
   cursor: pointer;
   background: #40b7d9;
   color: #ffffff;
   border: none;
}

.mailing-notification-button:hover {
   background-color: #4fc6e8;
}

.mailing-notification-button:active {
   background-color: #39b0d2;
}
