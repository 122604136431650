.settings-template-wrapper {
   padding: 30px;
   overflow: auto;
}

@media screen and (max-width: 900px) {
   .settings-template-wrapper {
      padding: 15px 50px 30px;
   }
}

.settings-template_block {
   display: flex;
   flex-direction: column;
   gap: 27px;
}

.settings-template__actions {
   display: flex;
   gap: 20px;
}

.settings-template_button {
   width: 181px;
   height: 47px;
   box-shadow: 0 6px 20px rgba(108, 197, 222, 0.2);
   border-radius: 6px;
   border: 0 solid transparent;
   background: #40b7d9;
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 19px;
   color: #ffffff;
   cursor: pointer;
}

.settings-template_button:hover {
   background-color: #4fc6e8;
}

.settings-template_button:active {
   background-color: #39b0d2;
}

.settings-template_button:disabled {
   background-color: #b8c5c9;
   box-shadow: 0 6px 18px rgba(186, 188, 189, 0.2);
}

.settings-template_file-button {
   display: flex;
   flex-direction: row;
   align-items: center;
   gap: 14px;
   cursor: pointer;
   background: #ffffff;
   color: rgba(0, 172, 239, 1);
}

.settings-template_file-button svg {
   fill: rgba(0, 172, 239, 1);
}

.settings-template_file-button:hover,
.settings-template_file-button:hover svg {
   color: rgba(54, 194, 249, 1);
   fill: rgba(54, 194, 249, 1);
}

.settings-template_file-button:active,
.settings-template_file-button:active svg {
   color: rgba(21, 167, 224, 1);
   fill: rgba(21, 167, 224, 1);
}

.settings-template_file-button-limit {
   display: flex;
   flex-direction: row;
   align-items: center;
   gap: 14px;
   cursor: pointer;
   background: #ffffff;
   color: rgba(197, 199, 208, 1);
}

.template-settings_list {
   margin-top: 36px;
   box-shadow: 0px 6px 30px rgba(213, 230, 235, 0.4);
   border-radius: 4px;
   margin-bottom: 26px;
}

.settings-template_file-input {
   width: 0.1px;
   height: 0.1px;
   opacity: 0;
   overflow: hidden;
   position: absolute;
   z-index: -1;
}

.template-header {
   list-style: none;
   padding: 20px 24px;
}

.template-header_block {
   width: 100%;
   display: grid;
   grid-template-columns: 2fr 2fr 7fr 3fr 1fr;
   grid-auto-flow: row;
   grid-gap: 20px 60px;
   font-size: 14px;
   line-height: 17px;
   font-weight: 600;
   color: #252525;
   opacity: 0.4;
}

.template-item {
   list-style: none;
   border-top: 1px solid #e4e4e4;
   padding: 20px 24px;
}

.template-item_block {
   width: 100%;
   display: grid;
   grid-template-columns: 2fr 2fr 7fr 3fr 1fr;
   grid-auto-flow: row;
   grid-gap: 20px 60px;
   font-size: 14px;
   line-height: 17px;
   font-weight: 400;
   color: #252525;
}

.template-subsection-item .template-item_block {
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.template-item_name {
}

.template-item_text {
}

.template-item-value {
   word-break: break-word;
}

.template-item_file-ref {
   text-decoration: none;
   color: #40b7d9;
}

.template-item_file {
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   color: #4bade9;
}

.template-item_buttons {
   display: flex;
   flex-direction: row;
   gap: 18px;
}

.template-item_setting_button {
   cursor: pointer;
}

.template-subsections {
   display: flex;
   flex-direction: column;
}

.template-subsections:not(:first-child) {
   margin-top: 30px;
}
