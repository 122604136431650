.loader-container {
   flex: 1 1 auto;
   display: flex;
   justify-content: center;
   align-items: center;
}

.loader {
   width: 64px;
   height: 64px;
   padding: 0 8px;
   display: flex;
   align-items: center;
   justify-content: space-between;
}
 
.element {
   width: 8px;
   height: 32px;
   border-radius: 12px;
   background-color: #e4e4e4;
}
 
.element1 {
   animation: bounce1 1.2s infinite;
   animation-delay: 0s;
}
 
.element2 {
   animation: bounce2 1.2s infinite;
   animation-delay: 0.3s;
}
 
.element3 {
   animation: bounce1 1.2s infinite;
   animation-delay: 0.6s;
}
 
.element4 {
   animation: bounce2 1.2s infinite;
   animation-delay: 0.9s;
}
 
@keyframes bounce1 {
   0%, 100% {
     height: 32px;
     background-color: #e4e4e4;
   }
   33.33% {
     height: 64px;
     background-color: #00acef;
   }
   66.66% {
     height: 40px;
     background-color: #00acef;
   }
}

@keyframes bounce2 {
   0%, 100% {
     height: 32px;
     background-color: #e4e4e4;
   }
   33.33% {
     height: 64px;
     background-color: #f36562;
   }
   66.66% {
     height: 40px;
     background-color: #f36562;
   }
}