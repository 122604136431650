.reset-password-page__card {
   max-width: 600px;
}

.reset-password-page__wrapper {
   display: flex;
   flex-direction: column;
   gap: 40px;
}

.reset-password-page__header-title {
   position: relative;
}

.reset-password-page__return-btn {
   position: absolute;
   top: 50%;
   left: 0;
   transform: translateY(-50%);
   width: 32px;
   height: 32px;
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;
}

.reset-password-page__return-btn img {
   margin: 0;
}

.reset-password-page__header-text {
   font-weight: 600;
   font-size: 28px;
   line-height: 32px;
   text-align: center;
}

.reset-password-form {
   display: flex;
   flex-direction: column;
   gap: 20px;
}

.reset-password-form__body {
   display: flex;
   flex-direction: column;
   gap: 12px;
}

.reset-password-form__status {
   text-align: center;
   color: #d94040;
}

.reset-password-form-control {
   display: flex;
   flex-direction: column;
   gap: 4px;
}

.reset-password-form-control__label {
   font-weight: 400;
   font-size: 12px;
   line-height: 16px;
   color: rgba(37, 37, 37, 1);
}

.reset-password-form-control__label_error {
   color: #d94040;
}

.reset-password-form-control__body {
   position: relative;
}

.reset-password-form-input {
   height: auto;
   padding: 11px 16px;
   border: 1px solid rgba(228, 228, 228, 1);
   border-radius: 12px;
   font-weight: 400;
   font-size: 14px;
   line-height: 18px;
}

.reset-password-form-input_password {
   padding-right: 44px;
}

.reset-password-form-input__show-btn {
   position: absolute;
   top: 50%;
   right: 14px;
   width: 20px;
   transform: translateY(-50%);
   opacity: 0.5;
   cursor: pointer;
}

.reset-password-form-input__show-btn_active {
   opacity: 1;
}

.reset-password-form__submit-btn {
   min-height: 44px;
   border-radius: 6px;
   background-color: rgba(64, 183, 217, 1);
   font-weight: 500;
   font-size: 16px;
   line-height: 19.36px;
   text-align: center;
   color: white;
   cursor: pointer;
}

.reset-password-form__submit-btn:disabled {
   background-color: rgba(228, 228, 228, 1);
}

@media screen and (max-width: 575.99px) {
   .reset-password-page__wrapper {
      gap: 20px;
   }

   .reset-password-page__return-btn {
      flex: 0 0 24px;
      width: 24px;
      height: 24px;
   }

   .reset-password-page__return-btn img {
      max-width: 8px;
   }

   .reset-password-page__header-text {
      font-size: 20px;
      line-height: 24px;
   }

   .forgot-password-form {
      gap: 20px;
   }

   .reset-password-form__body {
      gap: 8px;
   }
}