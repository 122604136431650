.number-input,
.number-input__container  {
   display: flex;
   align-items: center;
   gap: 12px;
}

.number-input {
   flex-wrap: wrap;
}

.number-input__icon {
   flex: 0 0 24px;
   width: 24px;
   height: 24px;
   margin: 0;
}

.number-input__name,
.number-input__mesurement-unit {
   font-size: 16px;
   line-height: 1.25;
   color: #8C9497;
}

.number-input__input {
   width: 56px;
   min-height: 30px;
   padding: 7px 15px;
   border: 1px solid #E4E4E4;
   border-radius: 12px;
   outline: none;
}

.number-input__mesurement-unit {
   font-weight: 500;
   color: #252525;
}