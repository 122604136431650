.mailing-modal {
   width: 460px;
}

.add-mailing-modal__wrapper {
   width: 460px;
   padding: 42px 40px;
   border-radius: 12px;
   display: flex;
   flex-direction: column;
   gap: 20px;
}

.add-mailing-modal__header {
   margin-bottom: 2px;
}

.add-mailing-modal__title {
   font-weight: 600;
   font-size: 20px;
   line-height: 24px;
}

.mailing-textarea {
   resize: none;
   height: 125px;
   display: inline-block;
   outline: none;
   border: 1px solid #e4e4e4;
   border-radius: 4px;
   padding: 14px 20px;
   font-size: 14px;
   line-height: 21px;
}

.mailing-textarea:focus {
   resize: none;
   height: 125px;
   display: inline-block;
   outline: none;
   border: 1px solid #40b7d9;
   border-radius: 4px;
   padding: 14px 20px;
   font-size: 14px;
   line-height: 21px;
}

.mailing-textarea-red {
   resize: none;
   height: 125px;
   display: inline-block;
   outline: none;
   border: 1px solid #d94040;
   border-radius: 4px;
   padding: 14px 20px;
   font-size: 14px;
   line-height: 21px;
}

.mailing-textarea-red::placeholder {
   color: #d94040;
   opacity: .4;
}

.mailing-label {
   font-style: normal;
   font-weight: 400;
   font-size: 12px;
   line-height: 15px;
   color: #252525;
   margin-bottom: 8px;
}

.mailing-label-red {
   font-style: normal;
   font-weight: 400;
   font-size: 12px;
   line-height: 15px;
   color: #d94040;
   margin-bottom: 8px;
}

.mailing-control-block {
   display: flex;
   flex-direction: column;
}

.mailing-tabs-form{
   display: flex;
   justify-content: space-between;
   gap: 20px;
}

.mailing-tabs-form__element {
   padding: 8px 12px;
   border-radius: 8px;
   background-color: white;
   font-style: normal;
   font-weight: 500;
   font-size: 16px;
   line-height: 16px;
   cursor: pointer;
   border-bottom: 1px solid rgba(0, 172, 239, 0);
}

.mailing-tabs-form__element:hover{
   background-color: rgba(64, 183, 217, 1);
   color: white;
}

.mailing-tabs-form__element.active{
   background-color: rgba(64, 183, 217, 1);
   color: white;
}

.mailing-single-contact-field{
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   position: relative;
   background-color: #e0e0e0;
   padding: 2px 25px 2px 8px;
   border-radius: 3px;
   font-size: 16px;
   cursor: default;
   max-width: calc(50% - 3px);
}

.mailing-list_delete-button{
   position: absolute;
   right: 5px;
   top: 50%;
   -ms-transform: translate(0,-50%);
   -webkit-transform: translate(0,-50%);
   transform: translate(0,-50%);
   cursor: pointer;
}

.mailing-list_input-wrap{
   width: 100%;
   display: flex;
   flex-direction: column;
   gap: 8px;
}

.mailing-list_input-wrap-container {
   display: flex;
   align-items: center;
   gap: 10px;
}

.mailing-list_input-wrap-container .mailing-list_input {
   flex: 1 1 auto;
   margin-right: 0;
}

.mailing-list_input{
   width: 100%;
   display: inline-block;
   outline: none;
   border: 1px solid #e4e4e4;
   border-radius: 4px;
   padding: 0 20px;
   height: 40px;
   font-size: 14px;
   line-height: 21px;
   margin-right: 10px;

}

.mailing-list-add-contact-button {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   padding: 0 15px;
   border-radius: 6px;
   background-color: #00acef;
   color: #fff;
   border: none;
   cursor: pointer;
   font-style: normal;
   font-weight: 500;
   font-size: 16px;
   line-height: 19px;
   height: 40px;
}

.cssAnimate {
   -moz-transition: all 0.3s linear;
   -o-transition: all 0.3s linear;
   -webkit-transition: all 0.3s linear;
}

.mailing-contact-list-display {
   display: flex;
   flex-wrap: wrap;
   gap: 6px;
   margin: 10px 0;
}

.mailing-contact-list-block {
   margin-bottom: 10px;
   display: flex;
   flex-direction: column;
}

.mailing-contact-file-info {
   display: inline-flex;
   cursor: pointer;
   position: relative;
   align-items: center;
   justify-content: center;
}

.mailing-contact-file-info:hover .mailing-contact-file-info-block {
   opacity: 1;
}

.mailing-contact-file-info-block {
   color: #000000;
   position: absolute;
   top: 18px;
   left: 15px;
   padding: 12px;
   background: #ffffff;
   border-radius: 6px;
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.205);
   font-size: 14px;
   line-height: 18px;
   opacity: 0;
   z-index: 10;
   width: 370px;
   pointer-events: none;
}

.mailing-label.question {
   display: flex;
   align-items: center;
}

.dropdown-list_item {
   display: flex;
   align-items: center;
   gap: 10px;
}

.add-mailing-form-submit {
   display: flex;
   flex-direction: column;
}