.custom-player {
}

.player-container {
    display: none;
}

.custom-player-wrap {
    background-color: #f1f1f1f1;
    border-radius: 50px;
    height: 54px;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    align-items: center;
    padding: 0 12px;
    justify-content: space-between;
}

.custom-player-control {
    display: flex;
    align-items: center;
}

.custom-player-button {
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    border-radius: 15px;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    align-items: center;
    cursor: pointer;
}

.custom-player-start-button {
    position: relative;
    width: 30px;
    height: 30px;
}

.custom-player-start-button:disabled {
    pointer-events: none;
}

.custom-player-start-button:disabled:after {
    border-left: 12px solid #ababab;
}

.custom-player-start-button:after {
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-left: 12px solid #222222;
    border-bottom: 7px solid transparent;
    z-index: 3;
}

.custom-player-stop-button {
    position: relative;
    width: 30px;
    height: 30px;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.custom-player-stop-button {
    width: 30px;
    height: 30px;
}

.custom-player-stop-button-img-wrap {
    width: 16px;
    height: 16px;
}

.custom-player-stop-button-img {
    width: 100%;
    display: block;
}

.custom-player-nav {
    display: flex;
    align-items: center;
    margin: 0 5px;
}

.custom-player-nav-current {
}

.custom-player-nav-progress-wrap {
    padding: 0 10px;
}

.custom-player-nav-progress {
    width: 110px;
    height: 4px;
    background: #797979;
    position: relative;
    border-radius: 15px;
}

.custom-player-nav-progress-val {
    position: absolute;
    height: 100%;
    background: #000000;
    border-radius: 15px;
}

.custom-player-nav-total {
}

.custom-player-nav-time {
    color: #000000;
    margin-right: 5px;
    display: flex;
    align-items: center;
}

.custom-player-nav-separate {
    margin: 0 3px;
}

.custom-player-right {
}

.custom-player-download-file {
    width: 30px;
    height: 30px;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.custom-player-download-file-img-wrap {
    width: 16px;
    height: 16px;
}

.custom-player-download-file-img {
    width: 100%;
    display: block;
}

.custom-player-download-mute {
}

.custom-player-download-mute-button {
    width: 30px;
    height: 30px;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.custom-player-download-mute-img-wrap {
    width: 16px;
    height: 16px;
}

.custom-player-download-mute-img {
    width: 100%;
    display: block;
}

@media (max-width: 720px) {
    .custom-player-wrap {
        width: 100%;
    }

    .custom-player-nav-time {
        display: none;
    }

    .custom-player-nav {
        width: 100%;
    }

    .custom-player-nav-progress-wrap {
        padding: 0 5px;
        width: 100%;

    }

    .custom-player-nav-progress {
        width: 100%;
    }

    .custom-player-nav-progress-val:after {
        top: 50%;
        -ms-transform: translate(0, -50%);
        -webkit-transform: translate(0, -50%);
        transform: translate(0, -50%);
        right: -7px;
        position: absolute;
        content: '';
        width: 12px;
        height: 12px;
        background: #000000;
        z-index: 4;
        border-radius: 50%;
    }
}