.notification {
   position: absolute;
   top: -82px;
   left: 50%;
   width: 57.16%;
   padding-right: 8px;
   min-height: 42px;
   transform: translate(-50%);
   border-radius: 8px;
   display: flex;
   gap: 8px;
   background-color: white;
   box-shadow: 0 4px 21.6px 0 rgba(186, 186, 186, 0.5);
   overflow: hidden;
}

.notification::before {
   content: "";
   width: 8px;
   background-color: gray;
}

.notification_success::before {
   background-color: #43DB4A;
}

.notification__body {
   flex: 1 1 auto;
   display: flex;
   align-items: center;
   gap: 8px;
}

.notification__body img {
   margin: 0;
}

.notification__close-btn {
   display: flex;
   align-items: center;
   cursor: pointer;
}

@media screen and (max-width: 575.99px) {
   .notification {
      width: calc(100% - 30px);
      top: auto;
      bottom: 58px;
   }
}