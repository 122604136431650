.tag-dropdown {
   position: relative;
   width: 200px;
   margin-left: -18px;
   border-radius: 6px;
}

.tag-dropdown.active {
   background-color: white;
   box-shadow: 0 6px 20px 0 rgba(213, 230, 235, 0.4);
}

.tag-dropdown__btn {
   min-height: 25px;
   padding: 4px 18px;
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 5px;
   font-weight: 400;
   font-size: 14px;
   line-height: 16.94px;
   color: rgba(64, 183, 217, 1);
   cursor: pointer;
}

.tag-dropdown__btn svg {
   transform: rotate(180deg);
   transition: all 0.3s ease;
}

.tag-dropdown.active .tag-dropdown__btn svg {
   transform: rotate(0);
}

.tag-dropdown__list {
   position: absolute;
   z-index: 5;
   top: 100%;
   left: 0;
   width: 100%;
   max-height: 180px;
   border-bottom-left-radius: 6px;
   border-bottom-right-radius: 6px;
   list-style: none;
   overflow: auto;
   display: flex;
   flex-direction: column;
   background-color: white;
   visibility: hidden;
   opacity: 0;
   transition: all 0.3s ease;
}

.tag-dropdown.active .tag-dropdown__list {
   visibility: visible;
   opacity: 1;
}

.tag-dropdown__list-item {
   padding: 4px 18px;
   font-weight: 400;
   font-size: 14px;
   line-height: 16.94px;
   color: rgba(64, 183, 217, 1);
   cursor: pointer;
}

.tag-dropdown__list-item:hover {
   background-color: rgb(244, 246, 248);
}
