.modal {
   position: fixed;
   z-index: 100;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   padding: 15px;
   display: flex;
   justify-content: center;
   align-items: center;
   background: rgba(0, 0, 0, 0.2);
   visibility: hidden;
   opacity: 0;
   transition: all 0.3s ease;
}

.modal.active {
   visibility: visible;
   opacity: 1;
}

.modal__body {
   flex: 1 1 auto;
   max-width: 460px;
   padding: 40px;
   border-radius: 12px;
   display: flex;
   flex-direction: column;
   gap: 32px;
   background-color: white;
}

.modal__header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   gap: 10px;
}

.modal__title {
   font-weight: 600;
   font-size: 20px;
   line-height: 1.2;
}

.modal__close-btn {
   display: flex;
   align-items: center;
   cursor: pointer;
}